<template>
    <div v-if="open" class="modal-otp">
        <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center overflow-hidden">
            <div @click="on_close" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>

            <div class="modal-container bg-white w-11/12 lg:w-1/3 md:w-2/4 sm:w-9/12  mx-auto rounded shadow-lg z-50 text-black">
                <!-- Add margin if you want to see some of the overlay behind the modal-->
                <div class="modal-content py-4 text-left px-2 sm:px-6">
                    <!--Title-->
                    <div class="flex justify-between items-center pb-3 px-3">
                        <p class="font-bold">OTP Verification</p>
                        <div @click="on_close" class="modal-close cursor-pointer z-50">
                            <em class="fas fa-times"></em>
                        </div>
                    </div>
                    <hr class="mb-2" />
                    <!--Body-->
                    <div class="flex flex-wrap flex-col mb-3 text-center otp-div">
                        <div class="flex flex-col my-4">
                            <span>Enter the OTP you received at</span>
                            <span class="font-bold">{{ otp.phone }}</span>
                            <span class="font-bold">Ref. {{ otp.refno }}</span>
                        </div>
                        <VOtpInput
                            ref="otpInput"
                            input-classes="otp-input"
                            separator=""
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :conditionalClass="['one', 'two', 'three', 'four']"
                            @on-change="handleOnChange"
                        />
                        <div class="my-4">
                            <button @click="verify_pin" :class="pin.length == 6 ? 'bg-green-500 hover:bg-green-600' : 'bg-green-200 cursor-not-allowed' " class="w-50 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-white font-medium border">
                                Confirm
                            </button>
                        </div>
                        
                        <div class="flex justify-center text-center">
                            <button v-if="count_down_resend > 0" class="flex items-center text-blue-300 cursor-not-allowed"><span class="font-bold mr-2">{{ count_down_resend }} Resend OTP</span> <em class="fas fa-redo"></em></button>
                            <button v-if="count_down_resend == 0" @click="resend_otp" class="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer"><span class="font-bold mr-2">Resend OTP</span> <em class="fas fa-redo"></em></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { useSweetalertError } from '@/assets/js/sweetalert'
import VOtpInput from 'vue3-otp-input';

export default {
    name: "Otp",
    props: ["otp", "open", "on_close", "get_otp"],
    components: {
        VOtpInput,
    },
    data() {
        let pin = "";
        let count_down_resend = 60;
        return {
            pin,
            count_down_resend
        }
    },
    methods: {
        handleOnChange(value){
            if(value.length==6){
                this.pin = value
            }else{
                this.pin = ""
            }
        },
        verify_pin(){
            this.$store.commit('setLoading', true);
            if(this.pin.length!=6){
                this.$store.commit('setLoading', false);
                useSweetalertError("กรุณากรอก OTP ให้ครบ");
                return false;
            }

            axios.post(`${process.env.VUE_APP_MIS_URL}/api/otp/verify`, {token:this.otp.token, pin:this.pin})
                .then(response => {
                    this.$store.commit('setLoading', false)
                    if(response.data.status==0){
                        this.$emit("otp_success");
                    }else{
                        useSweetalertError(response.data.message);
                    }
                }).catch( error => {
                    useSweetalertError(error.message);
                })
        },
        resend_otp(){
            this.get_otp();
            this.count_down_resend=60;
            this.countDownTimer();
        },
        countDownTimer () {
            if (this.count_down_resend > 0) {
                setTimeout(() => {
                    this.count_down_resend -= 1
                    this.countDownTimer()
                }, 1000)
            }
        }
    },
    mounted() {
        this.countDownTimer()
    },
};
</script>

<style scope>
    .otp-div{
        align-items: center;
    }
    .otp-input {
        width: 40px;
        height: 40px;
        padding: 5px;
        margin: 0 3px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;
    }
    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>
