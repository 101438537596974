import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Register from '@/views/Register.vue'
import Status from '@/views/Status.vue'





const routes = [
    {
        path: '/',
        name: 'Register',
        component: Register,
        meta: {
            title: 'After Service | The Digital STM'
        }
    },
    {
        path: '/status',
        name: 'Status',
        component: Status,
        meta: {
            title: 'Status | The Digital STM'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router