import Swal from 'sweetalert2'

export function useSweetalertTopEnd(timer, icon, title){
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    Toast.fire({
        icon: icon,
        title: title
    })
}
export function useSweetalertError(message){
    Swal.fire({
        icon: 'error',
        text: message,
        confirmButtonColor: '#3085d6',
        confirmButtonText:'OK',
    });
}
export function useSweetalertWarrnty(){
    Swal.fire({
        icon: 'error',
        text: "Please register warranty./ กรุณาลงทะเบียนรับประกัน",
        confirmButtonColor: '#8fce00',
        confirmButtonText:'Register',
    }).then((result) => {
        if (result.isConfirmed) {
            window.open(`${process.env.VUE_APP_MIS_URL}/warranty`);
        }
    });
}