<template>
    <div v-if="open" class="scanner">
        <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center overflow-hidden">
            <div @click="on_close" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>

            <div class="modal-container bg-white lg:w-1/2 sm:w-9/12  mx-auto rounded shadow-lg z-50">
                <!-- Add margin if you want to see some of the overlay behind the modal-->
                <div class="modal-content py-4 text-left px-6">
                    <!--Title-->
                    <div class="flex justify-between items-center pb-3">
                        <p class="font-bold">{{ data_scanner.title }}</p>
                        <div @click="on_close" class="modal-close cursor-pointer z-50">
                            <em class="fas fa-times"></em>
                        </div>
                    </div>
                    <hr class="mb-2" />
                    <!--Body-->
                    <div class="flex flex-wrap mb-3">
                        <div class="w-full lg:px-3 sm:px-1">
                            <div class="loading-indicator text-center text-blue-300" v-if="loading">
                                <em class="fas fa-circle-notch fa-spin fa-2x"></em>
                            </div>
                            <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded">
                            </StreamBarcodeReader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
    name: "Scanner",
    props: ["open", "on_close", "data_scanner"],
    components:{
        StreamBarcodeReader
    },
    data() {
        let loading = true;
        return {
            loading
        }
    },
    methods: {
        onDecode(result){
            this.$emit("scan_decode", {label:this.data_scanner.label ,result: result});
            this.on_close();
        },
        onLoaded(){
            this.loading = false;
        }
    },
    unmounted() {
        this.loading = true;
    },
};
</script>

<style></style>
