<template>
    <div class="step-complete">
        <div class="bg-white rounded-lg p-10 flex items-center shadow justify-center">
            <div>
                <svg
                    class="mb-4 h-20 w-20 text-green-500 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                    />
                </svg>

                <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">
                    Registration Success
                </h2>

                <StepValidation 
                    :form="form" 
                    :brand_options="brand_options" 
                    :model_options="model_options" 
                    :province_options="province_options" 
                    :amper_options="amper_options" 
                    :tumbol_options="tumbol_options" 
                    text_color="text-black"
                />

                <button
                    @click="print"
                    class="w-60 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border border-yellow-200"
                >
                    Download ใบปะหน้า
                </button>

                <router-link
                    :to="'/status?service_code='+form.service_code"
                    class="w-60 mt-2 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border"
                >
                    Check status
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {printParcel} from "../assets/js/printParcel"
    import StepValidation from './StepValidation'

    export default {
        name: "StepComplete",
        props: ['form', 'brand_options', 'model_options', 'back_to_home', 'province_options', 'amper_options', 'tumbol_options'],
        components: {
            StepValidation,
        },
        methods: {
            print(){
                printParcel(this.form.full_name, this.form.phone, this.form.address, this.tumbol_options[this.form.tumbol], this.amper_options[this.form.amper], this.province_options[this.form.province], this.form.postal, this.form.service_code)
            }
        },
    };
</script>

<style>
    
</style>

