<template>
    <div class="header border-b-2 py-4 flex">
        <div class="">
            <img src="../assets/image/stm-white.png" alt="STM Logo" width="80">
        </div>
        <div class="flex-1 ml-3 pt-1">
            <div class="flex justify-between uppercase tracking-wide text-xs font-bold text-white mb-1 leading-tight">
                <div>Step: {{ step }} of 3</div>
                <div class="mr-2">Version : {{ version }}</div>
            </div>
            <div class="flex flex-col md:flex-row md:items-center md:justify-between">
                <div class="flex-1">
                    <div v-if="step === 1">
                        <div class="text-lg font-bold text-white leading-tight">
                            Product / สินค้า
                        </div>
                    </div>

                    <div v-if="step === 2">
                        <div class="text-lg font-bold text-white leading-tight">
                            Contract / ติดต่อ
                        </div>
                    </div>
                    <div v-if="step === 3">
                        <div class="text-lg font-bold text-white leading-tight">
                            Validation / ตรวจสอบข้อมูล
                        </div>
                    </div>
                </div>

                <div class="flex items-center md:w-64">
                    <div class="w-full bg-gray-200 rounded-full mr-2">
                        <div
                            class="rounded-full bg-green-500 text-xs leading-none h-2 text-center text-white"
                            :style="step === 1 ? 'width:0%' : step === 2 ? 'width:50%' : 'width:100%'"></div>
                    </div>
                    <div class="text-xs w-10 text-white">{{ step === 1 ? 0 : step === 2 ? 50 : 100 }}%</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { version } from '@/assets/js/version'

    export default {
        name: "Header",
        props: ['step'],
        data() {

            return {
                version
            }
        },
    };
</script>

<style>
    
</style>
