<template>
    <div class="step-validation">
        <div class="block mb-2 text-center">
            <span class="text-xs text-gray-400">เวลา </span> <br>
            <span :class="text_color" class="font-bold text-lg">{{ date_time_now }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Brand / ยี่ห้อ</span> <br>
            <span :class="text_color" class="font-bold text-lg">{{ brand_options.find(item=>item.value==form.brand_id).label }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Model / รุ่น</span> <br>
            <span :class="text_color" class="font-bold text-lg">{{ model_options.find(item=>item.value==form.model_id).label }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Serial Number</span> <br>
            <span :class="text_color" class="font-bold text-lg">{{ form.serial ? form.serial : '-' }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Courier / ช่องทางจัดส่ง</span> <br>
            <span :class="text_color" class="font-bold text-lg">{{ form.courior }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">รหัสส่งซ่อม</span> <br>
            <span :class="text_color" class="font-bold text-lg">{{ form.service_code ? form.service_code : '-' }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Full Name / ชื่อ - นามสกุล</span><br>
            <span :class="text_color" class="font-bold text-lg">{{ form.full_name }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Email / อีเมล</span><br>
            <span :class="text_color" class="font-bold text-lg">{{ form.email }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Mobile / เบอร์โทรศัพท์</span><br>
            <span :class="text_color" class="font-bold text-lg">{{ form.phone }}</span>
        </div>
        <div class="block my-2 text-center">
            <span class="text-xs text-gray-400">Address / ที่อยู่</span><br>
            <span :class="text_color" class="font-bold text-lg">{{ form.address }} {{ tumbol_options[form.tumbol] }} {{ amper_options[form.amper] }} {{ province_options[form.province] }} {{ form.postal }}</span>
        </div>
        <div class="block my-4 text-center">
            <span :class="text_color" class="text-xs">**กรณีให้มีการจัดส่งสินค้ากลับผ่านทางระบบขนส่ง จะมีค่าใช้จ่ายในการจัดส่งเพิ่มเติม</span><br>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "StepValidation",
        props: ['form', 'brand_options', 'model_options', 'province_options', 'amper_options', 'tumbol_options', 'text_color'],
        data() {
            let date_time_now = moment().format("DD/MM/YYYY HH:mm");
            return {
                date_time_now
            }
        },
    };
</script>

<style>
    
</style>

