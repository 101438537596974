<template>
    <!-- This is an example component -->
    <div class="h-screen bg-black">
        <div v-if="loading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span class="text-blue-500 opacity-100 top-1/2 my-0 mx-auto block relative w-0 h-0" >
                <em class="fas fa-circle-notch fa-spin fa-5x"></em>
            </span>
        </div>
        <div v-if="!loading">
            <div class="max-w-3xl mx-auto px-4 pb-20 pt-8">
                <!-- Top Navigation -->
                <div class="header border-b-2 py-4 flex">
                    <div class="">
                        <img src="../assets/image/stm-white.png" alt="STM Logo" width="80">
                    </div>
                    <div class="flex-1 flex flex-col md:flex-row ml-3 pt-1 items-center justify-between">
                        <div class="text-lg font-bold text-white leading-tight">
                            Status / สถานะ<br>
                            รหัสส่งซ่อม {{ after_service.code }}
                        </div>
                        <div class="text-xs text-white">
                            Version : {{ version }}
                        </div>
                    </div>
                </div>
                <!-- /Top Navigation -->
                <div class="mt-5 text-white text-center">
                    <h2 class="text-xl font-bold my-1">{{ after_service.fullname }}</h2>
                    <p class="my-1"><span class="text-gray-200">สินค้า :</span> {{ after_service.model.barcode }} - {{ after_service.model.name }}</p>
                    <p class="my-1"><span class="text-gray-200">Serial Number :</span> {{ after_service.serial ? after_service.serial : '-' }}</p>
                    <p class="my-1"><span class="text-gray-200">อาการ :</span> {{ after_service.detail }}</p>
                    <p class="my-1"><span class="text-gray-200">เพิ่มเติม :</span> {{ after_service.addon ? after_service.addon : '-' }}</p>
                    <button
                        @click="print"
                        class="w-60 block mt-5 mb-2 mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border"
                    >
                        Download ใบปะหน้า
                    </button>
                </div>
                <!-- Step Content -->
                <div class="py-7 text-white grid grid-cols-12">
                    <ol class="col-start-2 col-span-10 md:col-start-5 md:col-span-7 relative border-l border-gray-200 dark:border-gray-700 ml-5">                  
                        <li v-for="step in after_service.step" :key="step.id" class="mb-10 ml-6">            
                            <span :class="after_service.status_id < step.id ? 'bg-yellow-400' : 'bg-green-400'" class="flex absolute -left-4 justify-center items-center w-8 h-8 rounded-full ring-6 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                 <em v-if="after_service.status_id < step.id" class="fas fa-sync-alt fa-spin"></em>
                                 <em v-if="after_service.status_id >= step.id" class="fas fa-check"></em>
                            </span>
                            <h3 class="flex items-center mb-1 text-lg font-semibold">{{ step.name }}</h3>
                            <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ step.step_at }}</time>
                        </li>
                    </ol>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { useSweetalertError } from '@/assets/js/sweetalert'
    import { version } from '@/assets/js/version'
    import { printParcel } from "../assets/js/printParcel"

    export default {
        name: "Status",
        components: {
        },
        data() {
            let loading = true;
            let step = 0;
            let after_service;
            
            return {
                loading,
                version,
                step,
                after_service
            }
        },
        mounted () {
            let urlParams = new URLSearchParams(window.location.search);
            let service_code = urlParams.get('service_code');


            axios.get(`${process.env.VUE_APP_MIS_URL}/api/after_service/${service_code}`)
                .then(response => {
                    this.loading = false;
                    if(response.data.status==0){
                        this.after_service = response.data.data;
                    }else{
                        useSweetalertError(response.data.message);
                    }
                }).catch( error => {
                    useSweetalertError(error.message);
                })
        },
        methods: {
            print(){
                printParcel(this.after_service.fullname, this.after_service.tel, this.after_service.address, this.after_service.tumbol_th.tb_th, this.after_service.amper_th.am_th, this.after_service.province_th.pv_th, this.after_service.postal, this.after_service.code)
            }
        },
    };
</script>

<style>
    
</style>

