import { jsPDF } from "jspdf";
import "../fonts/THSarabunNewBold-normal";

export function printParcel(full_name, phone, address, tumbol, amper, province, postal, service_code) {
    const doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
    });
    doc.setFont("THSarabunNewBold");
    doc.setFontSize(18);
    doc.text("ผู้ส่ง", 10, 20);
    doc.setFontSize(16);
    doc.text(full_name, 10, 30);
    doc.text(address, 10, 40);
    doc.text(`ตำบล${tumbol} อำเภอ${amper} จังหวัด${province}`, 10, 50);
    doc.text(postal, 10, 60);
    doc.text(`โทร ${phone}`, 10, 70);

    doc.setFontSize(18);
    doc.text("ผู้รับ", 210, 120);
    doc.setFontSize(16);
    doc.text("The Digital STM Limited Partnership", 210, 130);
    doc.text("3133/4 ถนนสุขุมวิท 101/2", 210, 140);
    doc.text("แขวงบางนาเหนือ เขตบางนา กรุงเทพมหานคร", 210, 150);
    doc.text("10260", 210, 160);
    doc.text("โทร 02-8215505", 210, 170);
    doc.text(`Service Code ${service_code}`, 210, 180);

    doc.save(`${service_code}.pdf`);
}