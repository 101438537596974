<template>
    <!-- This is an example component -->
    <div class="h-screen">
        <div v-if="this.$store.state.loading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span class="text-blue-500 opacity-100 top-1/2 my-0 mx-auto block relative w-0 h-0" >
                <em class="fas fa-circle-notch fa-spin fa-5x"></em>
            </span>
        </div>
        <div>
            <div class="max-w-3xl mx-auto px-4 pb-20 pt-8">
                <div v-if="step === 'complete'">
                    <StepComplete 
                        :back_to_home="back_to_home" 
                        :form="form"
                        :brand_options="brand_options" 
                        :model_options="model_options" 
                        :province_options="province_options" 
                        :amper_options="amper_options" 
                        :tumbol_options="tumbol_options" 
                     />
                </div>
                <div v-if="step === 0">
                    <div
                        class="bg-white rounded-lg p-10 flex items-center shadow justify-center"
                    >
                        <div>

                            <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">
                                Invaild URL
                            </h2>

                            <div class="text-gray-600 mb-8 text-center">
                                Please check url.
                            </div>

                        </div>
                    </div>
                </div>

                <div v-if="step != 'complete' && step != 0">
                    <!-- Top Navigation -->
                    <Header :step="step" />
                    <!-- /Top Navigation -->

                    <!-- Step Content -->
                    <div class="py-10">
                        <div v-if="step === 1">
                            <div class="mb-4">
                                <label for="serial" class="font-bold mb-1 text-white block">Serial Number (ถ้ามี)</label>
                                <div class="relative">
                                    <input 
                                        v-model="form.serial"
                                        @keypress.enter="get_serial"
                                        ref="serial"
                                        type="text" 
                                        name="serial" 
                                        class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600 border border-gray-300" 
                                        placeholder="Scan Serial Number..." 
                                        autocomplete="off"
                                    />
                                    <span class="absolute inset-y-0 right-0 flex items-center">
                                        <button @click="open_scanner('Scan Serial Number', 'serial')" class="px-2 py-1 mr-2 focus:outline-none focus:shadow-outline border border-gray-200 hover:bg-gray-200 rounded-2xl">
                                            <em class="fas fa-barcode"></em>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label for="brand_id" class="font-bold mb-1 text-white block">Brand / ยี่ห้อ <span class="text-red-500">*</span></label>
                                <Multiselect 
                                    v-model="form.brand_id"
                                    :options="brand_options"
                                    searchable
                                    @select="select_brand"
                                    @clear="clear_brand"
                                    placeholder="Select option"
                                />
                            </div>
                            <div class="">
                                <label for="model_id" class="font-bold mb-1 text-white block">Model / รุ่น <span class="text-red-500">*</span></label>
                                <Multiselect 
                                    v-model="form.model_id"
                                    :options="model_options"
                                    searchable
                                    @select="select_model"
                                    @clear="clear_model"
                                    placeholder="Select option"
                                />
                            </div>

                            <label class="flex justify-start text-truncate rounded-lg pl-4 pr-6 py-3 shadow-sm mr-4 my-2">
                                <div class="text-teal-600 mr-3">
                                    <input v-model="form.is_accept_price" type="checkbox" value="Accept_price" class="form-radio focus:outline-none focus:shadow-outline" />
                                </div>
                                <div class="select-none text-white font-bold text-sm">กรณีไม่ใช่สินค้าที่นำเข้าจาก ห้างหุ้นส่วนจำกัด เดอะดิจิตอล เอสทีเอ็ม อาจมีค่าใช้จ่ายในการตรวจสอบเบื้องต้น <br>โดยขึ้นอยู่กับประเภทของสินค้า หรืออาจไม่สามารถรับซ่อมได้ ซึ่งจะมีการติดต่อกลับอีกครั้ง</div>
                            </label>
                               
                            <div class="mb-4">
                                <label for="detail" class="font-bold mb-1 text-white block">Detail / อาการที่พบ <span class="text-red-500">*</span></label>
                                <textarea 
                                    v-model="form.detail" name="detail" id="detail" cols="20" rows="3" 
                                    ref="detail"
                                    class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600"
                                    placeholder="Enter your Detail..."
                                >
                                </textarea>
                            </div>

                            <div class="mb-4">
                                <label for="courior" class="font-bold mb-1 text-white block">
                                    Courier / ช่องทางจัดส่ง <span class="text-red-500">*</span>
                                </label>
                                <div class="relative">
                                    <input 
                                        v-model="form.courior"
                                        ref="courior"
                                        type="text" 
                                        name="courior" 
                                        id="courior"
                                        class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600 border border-gray-300 z-0" 
                                        placeholder="Enter Courier / ช่องทางจัดส่ง..." 
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                            
                            <div class="mb-4">
                                <label for="service_code" class="font-bold mb-1 text-white block">
                                    รหัสส่งซ่อม (ถ้ามี) 
                                    <em @click="open_ex = true" class="fas fa-question-circle cursor-pointer hover:text-gray-200"></em>
                                    
                                </label>
                                <div class="relative">
                                    <input 
                                        v-model="form.service_code"
                                        @keypress.enter="check_service_code"
                                        ref="service_code"
                                        type="text" 
                                        name="service_code" 
                                        id="service_code"
                                        class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600 border border-gray-300 z-0" 
                                        placeholder="Scan รหัสส่งซ่อม..." 
                                        autocomplete="off"
                                    />
                                    <span class="absolute inset-y-0 right-0 flex items-center">
                                        <button @click="open_scanner('Scan รหัสส่งซ่อม', 'service_code')" class="px-2 py-1 mr-2 focus:outline-none focus:shadow-outline border border-gray-200 hover:bg-gray-200 rounded-2xl">
                                            <em class="fas fa-barcode"></em>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                        <div v-if="step === 2">
                            <div class="mb-4">
                                <label for="full_name" class="font-bold mb-1 text-white block">Full Name / ชื่อ-นามสกุล <span class="text-red-500">*</span></label>
                                <input
                                    v-model="form.full_name"
                                    type="text"
                                    class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600"
                                    placeholder="Enter your Full name..."
                                    autofocus
                                />
                            </div>

                            <div class="mb-4">
                                <label for="email" class="font-bold mb-1 text-white block">Email / อีเมล <span class="text-red-500">*</span></label>
                                <input
                                    v-model="form.email"
                                    type="email"
                                    class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600"
                                    placeholder="Enter your email address..."
                                />
                            </div>
                            <div class="mb-4">
                                <label for="phone" class="font-bold mb-1 text-white block">Mobile / เบอร์โทรศัพท์ <span class="text-red-500">*</span></label>
                                <input
                                    v-model="form.phone"
                                    type="phone"
                                    class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600"
                                    placeholder="Enter your mobile..."
                                />
                            </div>
                            <div class="mb-4">
                                <label for="postal" class="font-bold mb-1 text-white block">Postal / รหัสไปรษณี <span class="text-red-500">*</span></label>
                                <Multiselect 
                                    v-model="form.postal"
                                    :options="postal_options"
                                    searchable
                                    @select="select_postal"
                                    @clear="clear_postal"
                                    placeholder="Select option"
                                />
                            </div>
                            <div class="mb-4">
                                <label for="province" class="font-bold mb-1 text-white block">Province / จังหวัด <span class="text-red-500">*</span></label>
                                <Multiselect 
                                    v-model="form.province"
                                    :options="province_options"
                                    searchable
                                    @select="select_province"
                                    @clear="clear_province"
                                    placeholder="Select option"
                                />
                            </div>
                            <div class="mb-4">
                                <label for="amper" class="font-bold mb-1 text-white block">District / อำเภอ <span class="text-red-500">*</span></label>
                                <Multiselect 
                                    v-model="form.amper"
                                    :options="amper_options"
                                    searchable
                                    @select="select_amper"
                                    @clear="clear_amper"
                                    placeholder="Select option"
                                />
                            </div>
                            <div class="mb-4">
                                <label for="tumbol" class="font-bold mb-1 text-white block">Sub District / ตำบล <span class="text-red-500">*</span></label>
                                <Multiselect 
                                    v-model="form.tumbol"
                                    :options="tumbol_options"
                                    searchable
                                    @clear="clear_tumbol"
                                    placeholder="Select option"
                                />
                            </div>
                            <div class="mb-4">
                                <label for="address" class="font-bold mb-1 text-white block">Address 1 / บ้านเลขที่, อาคาร, ซอย, ถนน <span class="text-red-500">*</span></label>
                                <textarea 
                                    v-model="form.address" name="address" id="address" cols="20" rows="3" 
                                    class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600"
                                    placeholder="Enter your address..."
                                >
                                </textarea>
                            </div>
                            <label class="flex justify-start items-center text-truncate rounded-lg pl-4 pr-6 py-3 shadow-sm mr-4">
                                <div class="text-teal-600 mr-3">
                                    <input v-model="form.accept" type="checkbox" value="Accept" class="form-radio focus:outline-none focus:shadow-outline" />
                                </div>
                                <div class="select-none text-white font-bold">I agree to <a href="https://www.thedigitalstm.com/privacy-policy" target="_blank" class="text-blue-400 underline">privacy and policy terms</a></div>
                            </label>
                            <div class="justify-center text-center mt-2" style="text-align:-webkit-center">
                                <VueRecaptcha ref="ref_recaptcha" :sitekey="site_key" @verify="form.recaptcha = true"></VueRecaptcha>
                            </div>
                        </div>
                        <div v-if="step === 3">
                            <StepValidation 
                                :form="form" 
                                :brand_options="brand_options" 
                                :model_options="model_options" 
                                :province_options="province_options" 
                                :amper_options="amper_options" 
                                :tumbol_options="tumbol_options" 
                                text_color="text-white"
                            />
                        </div>
                    </div>
                    <!-- / Step Content -->
                </div>
            </div>
            <ModalServiceCode :open="open_ex" :on_close="close_ex" />
            <div v-if="open_otp" class="block-otp">
                <Otp :open="open_otp" :otp="otp" :on_close="close_otp" :get_otp="get_otp" @otp_success="otp_success" />
            </div>
            <div v-if="open" class="block-sanncer">
                <Scanner :open="open" :on_close="close_scanner" :data_scanner="data_scanner" @scan_decode="scan_decode" />
            </div>
            <!-- Bottom Navigation -->
            <div
                class="fixed bottom-0 left-0 right-0 py-4 bg-white shadow-md z-50"
                v-if="step != 'complete' && step != 0 && !open_otp"
            >
                <Footer :step="step" :reset_data="reset_data" :next_step="next_step" :previous_step="previous_step" :complete_step="complete_step" />
            </div>
            <!-- / Bottom Navigation https://placehold.co/300x300/e2e8f0/cccccc -->
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import Multiselect from '@vueform/multiselect'
    import Header from '@/components/Header'
    import ModalServiceCode from '@/components/ModalServiceCode'
    import Otp from '@/components/Otp'
    import StepValidation from '@/components/StepValidation'
    import Footer from '@/components/Footer'
    import Scanner from '@/components/Scanner'
    import StepComplete from '@/components/StepComplete'
    import { useSweetalertError, useSweetalertWarrnty } from '@/assets/js/sweetalert'
    import { validation_email, validation_phone } from '@/assets/js/validation'
    import { VueRecaptcha } from 'vue-recaptcha'

    export default {
        name: "App",
        components: {
            Multiselect,
            Header,
            ModalServiceCode,
            Otp,
            StepValidation,
            Footer,
            Scanner,
            StepComplete,
            VueRecaptcha
        },
        data() {
            let loading = false;
            let step = 0;
            let open = false;
            let brand_options = [];
            let model_options = [];
            let data_scanner = {};
            let open_ex = false;
            let form = {
                brand_id:'',
                model_id:'',
                is_accept_price:0,
                serial:'',
                detail:'',
                courior:'',
                service_code:'',
                full_name:'',
                email:'',
                phone:'',
                postal:'',
                province:'',
                amper:'',
                tumbol:'',
                address:'',
                accept:false,
                ticket:'',
                recaptcha:false,
                is_solved:true
            };
            let postal_options = [];
            let province_options = [];
            let amper_options = [];
            let tumbol_options = [];
            let open_otp = false;
            let otp = {
                phone:'',    
                token:'',
                refno:'',    
                is_verify:false,    
            }
            let site_key = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
            return {
                loading,
                step,
                open,
                brand_options,
                model_options,
                data_scanner,
                open_ex,
                form,
                postal_options,
                province_options,
                amper_options,
                tumbol_options,
                open_otp,
                otp,
                site_key,
            }
        },
        methods: {
            select_brand() {
                this.$store.commit('setLoading', true);
                this.form.model_id = ''
                this.model_options = []

                axios.get(`${process.env.VUE_APP_MIS_URL}/api/models`, {params:{page_type:1, brand_val:this.form.brand_id}})
                    .then(response => {
                        this.$store.commit('setLoading', false);
                        this.model_options = response.data.data.map(item => {
                            return {
                                value : item.id,
                                label : `${item.barcode ? item.barcode+' - ' : '' }${item.name}`,
                                barcode : item.barcode
                            }
                        })
                    }).catch( error => {
                        useSweetalertError(error.message);
                    })
            },
            clear_brand() {
                this.form.brand_id = ''
                this.form.model_id = ''
                this.model_options = []
            },
            select_model() {
                console.log(this.form.model_id)
            },
            clear_model() {
                this.form.model_id = ''
                this.form.serial = ''
            },
            open_scanner(title, label){
                this.open = true;
                this.data_scanner.title = title;
                this.data_scanner.label = label;
            },
            close_scanner(){
                this.open = false;
            },
            close_ex(){
                this.open_ex = false;
            },
            scan_decode(response){
                let {label, result} = response;
                if(label == "serial"){
                    this.form.serial = result
                    this.get_serial()
                }else if(label == "service_code"){
                    this.form.service_code = result
                    this.check_service_code()
                }
            },
            get_serial(){
                this.form.brand_id = ''
                this.form.model_id = ''
                if(this.form.serial != ""){
                    this.$store.commit('setLoading', true);
                    axios.get(`${process.env.VUE_APP_MIS_URL}/api/serials/${btoa(this.form.serial)}`, {params:{check_warranty:true, skip_not_found: true}})
                        .then(response => {
                            this.$store.commit('setLoading', false);
                            if(response.data.status==0){
                                let result = response.data.data;
                                this.model_options = [{
                                    value: result.model_id, 
                                    label: `${result.barcode ? result.barcode+' - ' : '' }${result.model_name}`,
                                    barcode : result.barcode
                                }]
                                this.form.brand_id = result.brand_id
                                this.form.model_id = result.model_id
                                this.form.serial = result.serial
                                this.$refs.detail.focus();
                            }else{
                                if(response.data.errors=="Not_warranty"){
                                    useSweetalertWarrnty();
                                }else{
                                    useSweetalertError(response.data.message);
                                }
                                this.form.serial = ""
                            }
                        }).catch( error => {
                            useSweetalertError(error.message);
                        })
                }else{
                    useSweetalertError("Please Scan Serial Number");
                }
            },
            check_service_code(){
                if(this.form.service_code != ""){
                    axios.get(`${process.env.VUE_APP_MIS_URL}/api/after_service/${this.form.service_code}/duplicate`)
                    .then(response => {
                        if (response.data.status!=0) {
                            this.form.service_code = "";
                            useSweetalertError(response.data.message);
                        }
                    }).catch( error => {
                        useSweetalertError(error.message);
                    })
                }else{
                    useSweetalertError("Please Scan รหัสส่งซ่อม");
                }
            },
            select_postal() {
                this.form.province = ''
                this.province_options = []
                this.form.amper = ''
                this.amper_options = []
                this.form.tumbol = ''
                this.tumbol_options = []

                this.get_province();
            },
            clear_postal() {
                this.form.postal = ''
                this.form.province = ''
                this.province_options = []
                this.form.amper = ''
                this.amper_options = []
                this.form.tumbol = ''
                this.tumbol_options = []
            },
            select_province() {
                this.form.amper = ''
                this.amper_options = []
                this.form.tumbol = ''
                this.tumbol_options = []

                this.get_amper();
            },
            clear_province() {
                this.form.province = ''
                this.form.amper = ''
                this.amper_options = []
                this.form.tumbol = ''
                this.tumbol_options = []
            },
            select_amper() {
                this.form.tumbol = ''
                this.tumbol_options = []

                this.get_tumbol();
            },
            clear_amper() {
                this.form.amper = ''
                this.form.tumbol = ''
                this.tumbol_options = []
            },
            clear_tumbol() {
                this.form.tumbol = ''
            },
            close_otp(){
                this.open_otp = false;
            },
            otp_success(){
                this.open_otp = false;
                this.otp.is_verify = true;
                this.next_step();
            },
            reset_data(){
                if(this.step==1){
                    this.form = {
                        ...this.form,
                        brand_id:'',
                        model_id:'',
                        serial:'',
                        detail:'',
                        courior:'',
                        service_code:'',
                    };
                }
                if(this.step==2){
                    this.form = {
                        ...this.form,
                        full_name:'',
                        email:'',
                        phone:'',
                        postal:'',
                        province:'',
                        amper:'',
                        tumbol:'',
                        address:'',
                        accept:false,
                        recaptcha:false
                    };
                    this.province_options = [];
                    this.amper_options = [];
                    this.tumbol_options = [];
                }
            },
            previous_step(){
                this.step--;
                this.form.recaptcha = false;
            },
            async next_step(){
                this.$store.commit('setLoading', true)
                let array_input = [
                    {input:'brand_id', label:'Brand / ยี่ห้อ', step:1},
                    {input:'model_id', label:'Model / รุ่น', step:1},
                    {input:'is_accept_price', label:'ยอมรับเงือนไขของสินค้า', step:1},
                    {input:'detail', label:'Detail / อาการที่พบ', step:1},
                    {input:'courior', label:'Courier / ช่องทางจัดส่ง', step:1},
                    {input:'full_name', label:'Full Name / ชื่อ-นามสกุล', step:2},
                    {input:'email', label:'Email / อีเมล', step:2},
                    {input:'phone', label:'Mobile / เบอร์โทรศัพท์', step:2},
                    {input:'postal', label:'Postcode / รหัสไปรษณี', step:2},
                    {input:'province', label:'Province / จังหวัด', step:2},
                    {input:'amper', label:'District / อำเภอ', step:2},
                    {input:'tumbol', label:'Sub District / ตำบล', step:2},
                    {input:'address', label:'Address / ที่อยู่', step:2},
                ];
                let index_empty = array_input.findIndex(item => (this.form[item.input]=="" || this.form[item.input] == null) && item.step==this.step);
                if(index_empty >= 0){
                    useSweetalertError(`Please Select ${array_input[index_empty].label}`);
                    this.$store.commit('setLoading', false)
                    return false
                }

                if(this.step == 1){
                    const serial = this.form.serial ? await axios.get(`${process.env.VUE_APP_MIS_URL}/api/serials/${btoa(this.form.serial)}`, {params:{check_warranty:true, skip_not_found: true}}) : '';
                    if(this.form.serial && serial.data.status!=0){
                        this.$store.commit('setLoading', false)
                        if(serial.data.errors=="Not_warranty"){
                            useSweetalertWarrnty()
                        }else{
                            useSweetalertError(serial.data.message)
                            this.form.serial = ""
                        }
                    }else{
                        // this.get_warranty();
                        const after_service = this.form.service_code ? await axios.get(`${process.env.VUE_APP_MIS_URL}/api/after_service/${this.form.service_code}/duplicate`) : '';
                        this.$store.commit('setLoading', false)
                        if(this.form.service_code && after_service.data.status!=0){
                            useSweetalertError(after_service.data.message)
                            this.form.service_code = ""
                        }else{
                            this.step = 2;
                            window.scrollTo(0,0);
                        }
                    }
                }else if(this.step == 2){
                    if(this.form.email != "" && !validation_email(this.form.email)){
                        useSweetalertError(`Email is not valid.`)
                        this.$store.commit('setLoading', false)
                        return false
                    }
                    if(this.form.phone != "" && !validation_phone(this.form.phone)){
                        useSweetalertError(`Mobile / เบอร์โทรศัพท์ is not valid.`)
                        this.$store.commit('setLoading', false)
                        return false
                    }
                    if(!this.form.accept){
                        useSweetalertError(`Please agree to terms and conditions.`)
                        this.$store.commit('setLoading', false)
                        return false
                    }
                    if(!this.form.recaptcha){
                        useSweetalertError(`Please check reCAPTCHA.`)
                        this.$store.commit('setLoading', false)
                        return false
                    }
                    if(!this.otp.is_verify || (this.otp.phone != this.form.phone)){
                        this.get_otp()
                    }else{
                        this.$store.commit('setLoading', false);
                        this.step = 3;
                        window.scrollTo(0,0);
                    }
                }
            },
            get_otp(){
                this.$store.commit('setLoading', true);
                axios.post(`${process.env.VUE_APP_MIS_URL}/api/otp/request`, {phone:this.form.phone})
                    .then(response => {
                        this.$store.commit('setLoading', false);
                        if(response.data.status==0){
                            this.otp.token = response.data.data.token
                            this.otp.refno = response.data.data.refno
                            this.otp.phone = this.form.phone
                            this.open_otp = true;
                        }else{
                            useSweetalertError(response.data.message);
                        }
                    }).catch( error => {
                        this.$store.commit('setLoading', false);
                        useSweetalertError(error.message);
                    })
            },
            get_warranty(){
                if(this.form.serial && this.form.full_name==""){
                    axios.get(`${process.env.VUE_APP_MIS_URL}/api/warranty/${this.form.serial}`)
                        .then(response => {
                            let result = response.data.data;
                            this.form.full_name = result.fullname
                            this.form.email = result.email
                            this.form.phone = result.tel
                            this.form.address = result.address
                        }).catch( error => {
                            useSweetalertError(error.message);
                        })
                }
            },
            complete_step(){
                this.$store.commit('setLoading', true)
                axios.post(`${process.env.VUE_APP_MIS_URL}/api/customer/after_service`,this.form)
                    .then(response => {
                        if(response.data.status==0){
                            let result = response.data.data;
                            this.form.service_code = result.code;
                            this.step = 'complete';
                        }else{
                            useSweetalertError(response.data.message);
                        }
                        this.$store.commit('setLoading', false)
                    }).catch( error => {
                        useSweetalertError(error.message);
                        this.$store.commit('setLoading', false)
                    })

                axios.post(`${process.env.VUE_APP_CHAT_URL}/customer/save_inform`,this.form)
                    .then(response => {
                        console.log(response);
                    }).catch( error => {
                        console.log(error);
                    })
            },
            get_province(){
                axios.post(`${process.env.VUE_APP_MIS_URL}/api/address/province`,{postal: this.form.postal})
                    .then(response => {
                        this.province_options = response.data.data
                    }).catch( error => {
                        useSweetalertError(error.message);
                    })
            },
            get_amper(){
                axios.post(`${process.env.VUE_APP_MIS_URL}/api/address/amper`,{postal: this.form.postal, province: this.form.province})
                    .then(response => {
                        this.amper_options = response.data.data
                    }).catch( error => {
                        useSweetalertError(error.message);
                    })
            },
            get_tumbol(){
                axios.post(`${process.env.VUE_APP_MIS_URL}/api/address/tumbol`,{postal: this.form.postal, province: this.form.province, amper: this.form.amper})
                    .then(response => {
                        this.tumbol_options = response.data.data
                    }).catch( error => {
                        useSweetalertError(error.message);
                    })
            }
        },
        mounted () {
            this.$store.commit('setLoading', true);
            let urlParams = new URLSearchParams(window.location.search);
            let ticket = urlParams.get('ticket');
            if(!urlParams.get('ticket')){
                this.$store.commit('setLoading', false);
                this.step = 0;
            }else{
                this.form.ticket = ticket;
                this.step = 1;
            }

            axios.get(`${process.env.VUE_APP_CHAT_URL}/tickets/${ticket}/customer`)
                .then(response => {
                    let result = response.data;
                    this.form.full_name = result.regist_full_name;
                    this.form.email = result.email;
                    this.form.phone = result.phone;
                    this.form.postal = result.postal;
                    this.form.province = result.province;
                    this.form.amper = result.amper;
                    this.form.tumbol = result.tumbol;
                    this.form.address = result.address;
                    this.get_province()
                    this.get_amper()
                    this.get_tumbol()
                }).catch( error => {
                    console.log(error);
                })

            axios.get(`${process.env.VUE_APP_MIS_URL}/api/address/postal`)
                .then(response => {
                    this.postal_options = response.data.data
                }).catch( error => {
                    useSweetalertError(error.message);
                })

            axios.get(`${process.env.VUE_APP_MIS_URL}/api/brands`, {params:{page_type:1}})
                .then(response => {
                    this.$store.commit('setLoading', false);
                    this.brand_options = response.data.data.map(item => {
                        return {
                            value : item.id,
                            label : item.name
                        }
                    })
                }).catch( error => {
                    useSweetalertError(error.message);
                })
        }
    };
</script>

<style>
    body{
        background-color: #000000;
    }
    [type="checkbox"] {
        box-sizing: border-box;
        padding: 0;
    }

    .form-checkbox,
    .form-radio {
        /* -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; */
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        display: inline-block;
        vertical-align: middle;
        background-origin: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex-shrink: 0;
        color: currentColor;
        background-color: #fff;
        border-color: #e2e8f0;
        border-width: 1px;
        height: 1.4em;
        width: 1.4em;
    }

    .form-checkbox {
        border-radius: 0.25rem;
    }
    .form-checkbox:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
        border-color: transparent;
        background-color: currentColor;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .multiselect-caret, .multiselect-clear{
        z-index: 0 !important;
    }
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
